<template>
  <b-card>
    <GroupDiscountsForm :group-id="groupData.id" />
    <b-row>

      <!-- Group Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Group Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
            :src="groupData.avatar"
            :text="avatarText(groupData.title)"
            :variant="`light-${resolveUserBalanceVariant(groupData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ groupData.title }}
              </h4>
              <span class="card-text">{{ groupData.email }}</span>
            </div>
            <div class="row m-0">
              <b-button
                :to="{ name: 'apps-groups-edit', params: { id: groupData.id } }"
                variant="primary"
                class="mr-1 mb-1"
              >
                Редагувати
              </b-button>
              <b-button
                variant="outline-secondary"
                :to="{ name: 'apps-groups-contact', params: { id: groupData.id }}"
                class="mb-1 mr-1"
              >
                Звʼязатись з групою
              </b-button>
              <b-button
                  v-if="!isEndUser"
                  variant="primary"
                  class="mr-1 mb-1"
                  v-b-modal.discountGroupModal
              >
                Знижки
              </b-button>
              <b-button
                variant="danger"
                class="mb-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="deleteGroup(groupData.id)"
              >
                Видалити
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { onUnmounted } from '@vue/composition-api'
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useGroupsList from '../group-list/useGroupsList'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GroupDiscountsForm from "@/views/apps/group/group-discounts/GroupDiscountsForm.vue";
import {useBusinessSettings} from "@/composition/businessSettings/useBusinessSettings";

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, GroupDiscountsForm,
  },
  props: {
    groupData: {
      type: Object,
      required: true,
    },
  },
  setup() {

    const toast = useToast()

    const USER_APP_STORE_MODULE_NAME = 'app-group'

    const { isEndUser } = useBusinessSettings();

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const deleteGroup = (id) => {

      if (confirm("Ви впевнені?")) {


        store.dispatch('app-group/deleteGroup', id)
          .then(() => {
            router.push({ name: 'apps-groups-list' })
          }).catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Не вдалось видалити групу',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
            return false
          })
      }
    }

    const { resolveUserBalanceVariant, resolveUserStatus, resolveUserBalance } = useGroupsList()

    return {
      avatarText,
      resolveUserBalanceVariant,
      resolveUserStatus,
      resolveUserBalance,
      toast,
      deleteGroup,
      isEndUser,
    }
  },
}
</script>

<style>

</style>
