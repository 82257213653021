<template>
  <b-modal
      id="discountGroupModal"
      title="Шаблони групи"
      hide-footer
      no-close-on-backdrop
  >
    <b-list-group title="Активні шаблони">
      <b-list-group-item
          v-for="template in groupDiscounts"
          :key="template.templateId"
          class="d-flex justify-content-between align-items-center"
      >
        <span>{{ getDiscountTemplatesTitle(template.templateId) }}</span>
        <span>{{ getAccountsTypesTitle(template.typeAccountId) }}</span>
        <b-button
            @click="removeDiscountTemplate(template.typeAccountId)"
            variant="danger"
            size="sm"
        >
          Прибрати знижку
        </b-button>
      </b-list-group-item>
    </b-list-group>
    <AddDiscountForm :default-params="defaultFormParams" />
  </b-modal>
</template>

<script>

import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";
import { BListGroup, BListGroupItem, BButton } from "bootstrap-vue";
import AddDiscountForm from "@/views/apps/discounts/AddDiscountForm.vue";
import { useDiscountsTemplateChange } from "@/composition/discounts/useDiscountsTemplateChange";
import { useDiscountTemplatesFetch } from "@/composition/discounts/useDiscountTemplatesFetch";
import { useGroupDiscounts } from "@/composition/discounts/useGroupDiscounts";
import {onBeforeMount} from "@vue/composition-api";

export default {
  name: "DiscountCompanyModal",

  components: {
    BListGroup,
    BListGroupItem,
    BButton,
    AddDiscountForm
  },

  props: {
    groupId: {
      required: true,
    },
  },

  setup(props) {
    const { fetchGroupDiscounts, groupDiscounts } = useGroupDiscounts();
    const { getAccountsTypesTitle } = useAccountsTypes();
    const { changeDiscountTemplate } = useDiscountsTemplateChange();
    const { getDiscountTemplatesTitle, fetchDiscountTemplates, fetchAllDiscounts } = useDiscountTemplatesFetch();

    const { groupId } = props;

    const defaultFormParams = {
      targetType: 2,
      bundleIds: [groupId]
    };

    function removeDiscountTemplate(typeAccount) {
      let params = {
        targetType: 2,
        typeAccount: typeAccount,
        templateId: 0,
      }
      changeDiscountTemplate(params);
    }

    onBeforeMount(() => {
      fetchGroupDiscounts(groupId);
      fetchDiscountTemplates();
      fetchAllDiscounts();
    })

    return {
      groupDiscounts,
      defaultFormParams,

      getDiscountTemplatesTitle,
      getAccountsTypesTitle,
      removeDiscountTemplate,
    };
  },
};
</script>