<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="groupData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching group data
      </h4>
      <div class="alert-body">
        No group found with this group id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-groups-list'}"
        >
          Group List
        </b-link>
        for other groups.
      </div>
    </b-alert>

    <template v-if="groupData">

      <groups-driver-add-new
        :is-add-new-driver-sidebar-active.sync="isAddNewDriverSidebarActive"
        @refetch-data="refetchData"
      />

      <group-view-group-info-card :group-data="groupData" />


      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row class="align-items-end">

            <!-- Per Page -->
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показано</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>записів</label>
            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <div class="d-flex align-items-center justify-content-end">

                <b-button
                  variant="primary"
                  @click="isAddNewDriverSidebarActive = true"
                >
                  <span class="text-nowrap">Додати водія</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refDriverGroupListTable"
          class="position-relative"
          :items="fetchGroupsDrivers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Відповідних записів не знайдено"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Driver -->
          <template #cell(driver)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.fullName)"
                  :variant="`light-info`"
                  :to="{ name: 'apps-drivers-view', params: { id: data.item.profileId } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-drivers-view', params: { id: data.item.profileId } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.fullName }}
              </b-link>
              <!-- <small class="text-muted">@{{ data.item.username }}</small> -->
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'apps-drivers-view', params: { id: data.item.profileId } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Подробиці</span>
              </b-dropdown-item>
              <b-dropdown-item @click="dismissDriver(data.item.profileId)">
                <feather-icon icon="LockIcon" />
                <span class="align-middle ml-50">Видалити з групи</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} з {{ dataMeta.of }} записів</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalDrivers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <!-- <drivers-list /> -->

    </template>

  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import {
  BRow, BCol, BAlert, BLink, BTable, BPagination, BCard, BButton, BDropdown, BDropdownItem, BMedia, BAvatar,
} from 'bootstrap-vue';
import groupStoreModule from '../groupStoreModule';
import GroupViewGroupInfoCard from './GroupViewGroupInfoCard.vue';
import GroupsDriverAddNew from './GroupsDriverAddNew.vue';
import useGroupsDriversList from './useGroupsDriversList';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { avatarText } from '@core/utils/filter';
import axiosIns from '@axios';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTable,
    BPagination,
    BCard,
    BButton,
    vSelect,
    BDropdown, 
    BDropdownItem,
    BMedia,
    BAvatar,
    
    // Local Components
    GroupViewGroupInfoCard,
    GroupsDriverAddNew,
  },
  methods: {
    async dismissDriver(id) {
      const response = await axiosIns
        .post(`/business/driver-groups/${router.currentRoute.params.id}/drivers/dismiss`, {
          items: [
            id
          ]
        })
        .then(response => {
          this.refetchData();
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error managing drivers',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    }
  },
  setup(props, { emit }) {

    const toast = useToast();

    const isAddNewDriverSidebarActive = ref(false);

    const groupData = ref(null);

    const USER_APP_STORE_MODULE_NAME = 'app-group';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, groupStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-group/fetchGroup', { id: router.currentRoute.params.id })
      .then(response => { 
        const group = response.data.items.filter(group => group.id == router.currentRoute.params.id)[0];
        groupData.value = group;
      })
      .catch(error => {
        return false;
      });

    const {
      fetchGroupsDrivers,
      tableColumns,
      perPage,
      currentPage,
      totalDrivers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refDriverGroupListTable,
      refetchData,
    } = useGroupsDriversList(router.currentRoute.params.id);

    return {
      groupData,
      fetchGroupsDrivers,
      tableColumns,
      sortBy,
      isSortDirDesc,
      dataMeta,
      totalDrivers,
      perPage,
      perPageOptions,
      currentPage,
      refetchData,
      avatarText,
      refDriverGroupListTable,
      isAddNewDriverSidebarActive,
      toast,
    };
  },
};
</script>

<style>
</style>
