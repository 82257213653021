<template>
  <b-sidebar
    id="add-new-driver-sidebar"
    :visible="isAddNewDriverSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-driver-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Додати нового водія в групу
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

            <v-select
              v-model="driverData.driverId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="driversOptions"
              class="invoice-filter-select mr-1"
              placeholder="Виберіть водія"
              required
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Додати
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Відмінити
            </b-button>
          </div>
          <small class="text-danger mt-1 d-block" v-if="error">Неправильні дані або водій вже існує</small>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormRadio, BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import axiosIns from "@/libs/axios";
import router from '@/router'
import getCurrentInstance from 'vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormRadio,
    BInputGroup,

    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDriverSidebarActive',
    event: 'update:is-add-new-driver-sidebar-active',
  },
  props: {
    isAddNewDriverSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: false,
      driversOptions: [],
      driverId: 0,
    }
  },
  methods: {
    getDrivers: async function() {

      let driversOptions = []

      const response = await axiosIns
          .get('/business/drivers')
          .then(response => {
              return response.data.items
          })
          .catch(() => {
              return false
          })

      response.forEach(driver => {
          driversOptions.push({ label: driver.fullName, value: driver.profileId })
      });

      return driversOptions
    },

      // if (accounts.length > 0) {
      //   driverData.value.accounts = accounts.filter(account => account)
      // }
      // store.dispatch('app-driver/addDriver', {
      //     fullName: driverData.value.fullName,
      //     phone: driverData.value.phone,
      //     balanceType: parseInt(driverData.value.balanceType),  
      //     confirmationCode: driverData.value.confirmationCode,
      //   })
      //   .then(() => {
      //     emit('refetch-data')
      //     emit('update:is-add-new-driver-sidebar-active', false)
      //   }).catch(() => {
      //     driverData.value.error = true
      //   })
      // }
  },
  async mounted() {
    this.driversOptions = await this.getDrivers()
  },
  setup(props, { emit }) {
    const blankDriverData = {
      driverId: 0,
    }

    const driverData = ref(JSON.parse(JSON.stringify(blankDriverData)))
    const resetdriverData = () => {
      driverData.value = JSON.parse(JSON.stringify(blankDriverData))
    }

    const accounts = [false, false]

    const onSubmit = async function() {

      const response = await axiosIns
      .post(`/business/driver-groups/${router.currentRoute.params.id}/drivers/assign`, {
            items: [
              driverData.value.driverId.value
            ]
          })
          .then(response => {
            
            emit('refetch-data')
            emit('update:is-add-new-driver-sidebar-active', false)
            
            return response.data.items
          })
          .catch(() => {
            this.error = true
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetdriverData)

    return {
      driverData,
      onSubmit,
      accounts,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-driver-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
