import store from "@/store";
import { computed } from "@vue/composition-api";
import {useStoreModule} from "@/composition/useStoreModule";
import discountStoreModule from "@/store/discounts/discountStoreModule";


const STORE_MODULE_NAME = "app-discounts";
export function useGroupDiscounts() {

    const fetchGroupDiscounts = async (id) => {
        await store.dispatch(`${STORE_MODULE_NAME}/fetchGroupDiscounts`, id)
    };

    const groupDiscounts = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_GROUP_DISCOUNTS`]
    });

    useStoreModule(STORE_MODULE_NAME, discountStoreModule);

    return {fetchGroupDiscounts, groupDiscounts};
}