import axiosIns from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchGroups(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get('/business/driver-groups', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchGroup(ctx) { //, { id }
            return new Promise((resolve, reject) => {
                axiosIns
                    .get('/business/driver-groups')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addGroup(ctx, groupData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post('/business/driver-groups', groupData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteGroup(ctx, id) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .delete(`/business/driver-groups/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addTransfer(ctx, transferData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post('/business/transfer/cash', transferData.transferData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchGroupsDrivers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get(`/business/driver-groups/${queryParams.groupId}/drivers`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },


    },
}